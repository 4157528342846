<template>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="computedDateFormatted"
          :label="label"
          hint="d.m.Y format"
          dense
          class="me-3"
          outlined
          hide-details
          persistent-hint
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>

      <v-date-picker
        v-model="dateCopy"
        no-title
        color="primary"
        @input="menu = false"
        firstDayOfWeek="1"
        locale="de-DE"
      ></v-date-picker>
    </v-menu>

</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import { mdiCalendar } from '@mdi/js'

export default {
  props:{
    date:{
      type:String,
      default:"",
      required:true
    },
    name:{
      type:String,
      default:"",
      required:true
    },
    label:{
      type:String,
      default:"",
      required:true
    }
  },
  setup(props,{emit}) {
    const dateCopy = ref("")
    dateCopy.value = JSON.parse(JSON.stringify(props.date))

    watch(() => props.date, (newValue) => {
      dateCopy.value = JSON.parse(JSON.stringify(newValue))
    });


    const menu = ref(false)

    const formatDate = dates => {
      if (!dates) return null
      const [year, month, day] = dates.split('-')

      return `${day}.${month}.${year}`
    }

    let dateFormatted = formatDate(dateCopy.value)

    const parseDate = dates => {
      if (!dates) return null
      const [day,month, year] = dates.split('.')

      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    }

    const computedDateFormatted = computed(() => formatDate(dateCopy.value))

    watch(dateCopy, () => {
      dateFormatted = formatDate(dateCopy.value)
      emit("updateDate",props.name,dateCopy.value);
    })

    return {
      dateCopy,
      dateFormatted,
      menu,
      computedDateFormatted,
      parseDate,
      formatDate,

      icons: {
        mdiCalendar,
      },
    }
  },
}
</script>

