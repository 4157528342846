<template>
  <div>
      <div class="pushBox" v-show="isPreviewVisible">
        <div class="pushImage" v-if="image != ''">
          <v-img :src="image"></v-img>
        </div>
        <div class="pushHeadline">
          <span>Mozilla Firefox</span>
          <span @click="hidePreview" class="float-right">&times;</span>
        </div>
        <div class="pushMessage">
          <v-row>
            <v-col cols="3">
              <div class="pushIcon" v-if="icon != ''">
                <v-img :src="icon" :width="70"></v-img>
              </div>
            </v-col>
            <v-col cols="9">
              <div class="pushTitle">{{title}}</div>
              <div class="pushContent">{{text}}</div>
              <div class="pushUrl">{{domain}}</div>
            </v-col>
          </v-row>
        </div>
        <v-row class="pushButtons pa-2 mt-1">
          <v-col
            :cols="button2.aktiv ? '6' : '12'"
            v-if="button1.aktiv"
          >
            <a v-if="button1.aktion == 'bestaetigen'" :href="button1.url" target="_blank">
              <v-btn class="pushButton">
                <v-img
                  class="me-2"
                  v-if="button1.icon != ''"
                  :src="button1.icon"
                  :width="50"
                ></v-img>
                <span>Button 1</span>
              </v-btn>
            </a>
            <v-btn class="pushButton"
              v-if="button1.aktion != 'bestaetigen'"
              @click="hidePreview"
            >
              <v-img
                class="me-2"
                v-if="button1.icon != ''"
                :src="button1.icon"
                :width="50"
              ></v-img>
              <span>Button 1</span>
            </v-btn>
          </v-col>
          <v-col
            :cols="button1.aktiv ? '6' : '12'"
            v-if="button2.aktiv"
          >
            <a
              v-if="button2.aktion == 'bestaetigen'"
              :href="button2.url"
              target="_blank"
            >
              <v-btn class="pushButton">
                <v-img
                  class="me-2"
                  v-if="button2.icon != ''"
                  :src="button2.icon"
                  :width="50"
                ></v-img>
                <span>Button 2</span>
              </v-btn>
            </a>
            <v-btn class="pushButton"
              v-if="button2.aktion != 'bestaetigen'"
              @click="hidePreview"
            >
              <v-img
                class="me-2"
                v-if="button2.icon != ''"
                :src="button2.icon"
                :width="50"
              ></v-img>
              <span>Button 2</span>
            </v-btn>
          </v-col>
        </v-row>
      </div>
  </div>
</template>

<script>

export default {
  props:{
    isPreviewVisible:{
      type:Boolean,
      default:true,
      required:true
    },
    title:{
      type:String,
      default:"",
      required:false
    },
    text:{
      type:String,
      default:"",
      required:false
    },
    domain:{
      type:String,
      default:"",
      required:false
    },
    icon:{
      required:false
    },
    image:{
      required:false
    },
    button1:{
      required:false
    },
    button2:{
      required:false
    },
  },
  setup(props,{emit}) {

    const hidePreview = () => {
      emit("hidePreview");
    }

    return {
      hidePreview
    }
  },
}
</script>

<style scoped>

.pushBox{
  position: absolute;
  right:20px;
  bottom:20px;
  background-color: #b71111;
  width:450px;
  border-radius:6px;
  color: #f5f5f5;
}

.pushImage v-img{
  width:100%;
}
.pushHeadline{
  padding: 10px;
  font-size:80%
}
.pushHeadline span{
  cursor:pointer;
}
.pushMessage{
  padding: 10px;
}
.pushTitle{
  font-weight:bold;
}
.pushContent{
  color:#cecece;
}
.pushUrl{
  color:#cecece;
  font-size:80%
}
.pushButton{
  width:100%;
  background-color: #565656;
  color:#fff;
}

.pushButton span{
  font-weight:100;
  color:#000;
}
</style>
