<template>
  <div>
    <div class="selectBrowser">
      <v-select
        class="mb-5"
        v-model="selectedBrowser"
        hide-details="auto"
        outlined
        dense
        :items="browsers"
        item-text="label"
        item-value="value"
        placeholder="Browser"
      ></v-select>
    </div>

    <div class="previewBox">
      <chrome v-if="selectedBrowser == 'chrome'"
        :title="title"
        :text="text"
        :domain="domain"
        :icon="icon"
        :image="image"
        :button1="button1"
        :button2="button2"
        :isPreviewVisible="isPreviewVisible"
        @hidePreview="hidePreview"
      ></chrome>

      <firefox v-else-if="selectedBrowser == 'firefox'"
         :title="title"
         :text="text"
         :domain="domain"
         :icon="icon"
         :image="image"
         :button1="button1"
         :button2="button2"
         :isPreviewVisible="isPreviewVisible"
         @hidePreview="hidePreview"
      ></firefox>
    </div>

    <div class="controlButtons">
      <v-btn color="primary">
        An mich versenden
      </v-btn>
      <v-btn v-show="!isPreviewVisible" @click="showPreview">
        Notification öffnen
      </v-btn>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import Chrome from "@/views/push-notification/Chrome";
import Firefox from "@/views/push-notification/Firefox";

export default {
  components:{
    Chrome,
    Firefox
  },
  props:{
    title:{
      type:String,
      default:"",
      required:false
    },
    text:{
      type:String,
      default:"",
      required:false
    },
    domain:{
      type:String,
      default:"",
      required:false
    },
    icon:{
      required:false
    },
    image:{
      required:false
    },
    button1:{
      required:false
    },
    button2:{
      required:false
    },
  },
  setup(props,{emit}) {

    const browsers = ref([
      {label:"Google Chrome", value:"chrome"},
      {label:"Mozilla Firefox", value:"firefox"},
    ]);
    const selectedBrowser = ref("chrome");

    const isPreviewVisible = ref(true);
    const showPreview = () => {
      isPreviewVisible.value = true;
    };
    const hidePreview = () => {
      isPreviewVisible.value = false;
    };

    return {
      isPreviewVisible,
      selectedBrowser,
      browsers,
      showPreview,
      hidePreview,
    }
  },
}
</script>

<style>
.previewBox{
  position:relative;
  background-color:#d7d7d7;
  width:100%;
  height:100%;
  min-height: 500px;
}
.controlButtons{
  margin:10px 0px;
  display: flex;
  justify-content: space-between;
}
</style>
